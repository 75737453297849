import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsPercent, BsQuestionCircle } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";
import { VscCode } from "react-icons/vsc";
import { toast } from "react-toastify";
import useBodyScrollLoc from "./UseBodyScroolLock";
import emailjs from "@emailjs/browser";
import { useRef } from 'react';

const CouponCard = ({ coupon }) => {
  const [togglel, toggle, togglell, isLocked] = useBodyScrollLoc();

  const [loader, setloader] = useState(false);
  const btnRef = useRef(null);


  // send coupon mail

  // Ibrahim Email Credentials

  // const sendEmail = (coupon) => {
  //   emailjs
  //     .send(
  //       "service_5lsakdl",
  //       "template_6vkb6pa",
  //       { coupon: coupon.title.rendered },
  //       "cSa-Xe2kKqN3tYIU2"
  //     )
  //     .then(
  //       function (response) {
  //         console.log("SUCCESS!", response.status, response.text);
  //         toast("Email Sent");
  //       },
  //       function (error) {
  //         console.log("FAILED...", error);
  //         toast("Email Failed!");
  //       }
  //     );
  // };

  // Ibrahim Email Credentials

  // Harrison Email Credentials

  const sendEmail = (coupon) => {
    btnRef.current.classList.add('loading')
    
    setloader(true);
    emailjs
      .send(
        "service_yukqhrt",
        "template_akoei5t",
        { coupon: coupon.title.rendered },
        "FRs_bxr3mWbkiPpgi"
      )
      .then(
        function (response) {
          toast("Email Sent");
          btnRef.current.classList.remove('loading')
          console.log("SUCCESS!", response.status, response.text);
         
        },
        function (error) {
          console.log("FAILED...", error);
          toast("Email Failed!");
        }
      );
     
    // setloader(false);
  };

  // Harrison Email Credentials

  // copy to cliqboard state
  const [copied, copiedF] = useState(false);

  return (
    <div key={coupon.id} className="relative my-[18vh] cursor-pointer  mx-4">
      <label
        className={`absolute ${
          isLocked ? "w-full h-full" : "w-full h-full"
        }    cursor-pointer`}
        htmlFor={`my-modal-${coupon.id}`}
      ></label>
      <input
        type="checkbox"
        id={`my-modal-${coupon.id}`}
        className="modal-toggle"
      />

      <label htmlFor={`my-modal-${coupon.id}`} className="modal cursor-pointer">
        <label className="modal-box relative " htmlFor="">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Rabattkod:</h3>
            <p className="py-4 font-bold text-[20px]">{`${coupon.title.rendered}`}</p>

            <CopyToClipboard
              text={coupon.title.rendered}
              onCopy={() => {
                copiedF(true);

                toast("Copied to Clipboard");
              }}
            >
              <div className="flex flex-col items-center">
                <FiCopy size={23} color="black" className="cursor-pointer" />
                <p className="text-[13px]">Kopiera Text</p>
              </div>
            </CopyToClipboard>
          </div>
        </label>
      </label>

      {/* {Vs Code Modal at the Top} */}

      {/* {Vs Code Toggle at the Top} */}
      <button className="btn btn-circle btn-ghost hover:bg-[#26DBE7] bg-[#26DBE7] absolute top-4 left-4">
        <label className=" cursor-pointer" htmlFor={`my-modal-${coupon.id}`}>
          <BsPercent size={30} color="white" />
        </label>
      </button>
      {/* {Vs Code Toggle at the Top} */}

      {/* {Question Mark button Toggle at the Bottom} */}
      <button
        // onClick={() => togglell()}
        className="btn btn-circle btn-ghost hover:bg-[#4c4e4f] bg-[#4c4e4f] absolute bottom-9 right-5"
        htmlFor={`my-modal-${coupon.id}-buttom`}
      >
        <label
          htmlFor={`my-modal-${coupon.id}-buttom`}
          className="btn bg-transparent border-none hover:bg-[#4c4e4f] hover:bg-transparent"
        >
          <BsQuestionCircle size={24} color="white" />
        </label>
      </button>

      {/* {Question Mark button Toggle at the Bottom} */}

      {/* {Question Mark Modal at the Bottom} */}
      <input
        type="checkbox"
        id={`my-modal-${coupon.id}-buttom`}
        className="modal-toggle overflow-hidden "
      />

      <label
        htmlFor={`my-modal-${coupon.id}-buttom`}
        className="modal cursor-pointer"
      >
        <div className="h-full w-full bg-[rgba(0, 0, 0, 1)]  absolute   flex items-center justify-center">
          <div
            className="sm:h-[400px] sm:w-[400px] h-[300px] w-[300px] bg-[#4c4e4f] absolute z-40 flex flex-col rounded-lg 
"
          >
            <div className="h-[60%] w-full flex items-center justify-center bg-[#29a9e0] rounded-lg">
              <p className="text-white text-[16px] font-bold px-3 text-center">
                Tryck på meddela oss om rabattkoden inte fungerar?
              </p>
            </div>
            <div className="h-[40%] rounded-lg w-full  flex items-center justify-center">
              <button ref={btnRef}
                onClick={() => {
                  sendEmail(coupon);
                }}
                className="btn btn-sm  bg-[#26DBE7]  text-center px-6  py-2 rounded-lg cursor-pointer   outline-none text-[14px] font-semibold  text-white"
              >
                Meddela oss
              </button>
            </div>
          </div>
        </div>
      </label>

      {/* {Question Mark Modal at the Bottom} */}

      <img
        className="sm:w-[600px] sm:h-[500px] h-[350px]  rounded-2xl "
        src={coupon.acf.coupon_image}
        alt=""
      />
      <div className="avatar placeholder"></div>
    </div>
  );
};

export default CouponCard;
