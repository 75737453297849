import React from "react";
import Navbar from "../components/Navbar";

const AboutUs = () => {
  return (
    <main className="w-full overflow-x-hidden">
      <Navbar />
      <div className="bg-[#e3f0fb] min-h-screen pb-16">
        <div className="max-w-[1024px] mx-auto pt-12">
          <section className="text-center max-w-[460px] sm:max-w-[600px] md:max-w-[768px] mx-auto ">
            <h1 className="text-[#228ce0] text-[3rem] font-bold font-lato px-2">
              {/* Our Story */}
              Vår berättelse
            </h1>
            <p className="mt-4 px-8 font-lato text-[1.5rem]">
              Vi ger inte bara våra kunder möjlighet att spara pengar på sina
              inköp, vi ser fram emot en kontinuerlig tillväxt med vår lojala
              kundbas.
            </p>
            {/* <p className="mt-4 px-8 font-lato text-[1.5rem]">
              We not only empower our customers to save money on their
              purchases, we look forward to a continous growth with our loyal
              customer base.
            </p> */}
          </section>

          <section className="flex flex-col md:flex-row items-center space-between w-full px-4 mt-16">
            <div className="max-w-[600px] md:max-w-[460px] flex-1">
              <h4 className="text-[#228ce0] mb-4 mt-2 text-[1.5rem] font-semibold font-lato">
                {/* What we do */}
                Vad vi gör
              </h4>
              <p className="pr-4 font-lato text-[1.1rem]">
              Saltvatten Company grundades av en grupp entreprenörer som
                var trötta på att spendera timmar på att leta efter bra rabattkoder
                på olika webbplatser.Vi skapade en plattform som samlar alla
                rabattkoder på ett ställe så att kunderna enkelt kan hitta
                bästa erbjudanden.
              </p>
              {/* <p className="pr-4 font-lato text-[1.1rem]">
                Saltvatten Company was founded by a group of entrepreneurs who
                were tired of spending hours searching for good discount codes
                on different websites. We created a platform that collects all
                discount codes in one place so customers can easily find the
                best deals.
              </p> */}
             
            </div>
            <div className="flex-1 mb-4 md:mb-0 order-first md:order-last">
              <div className="max-w-[420px] md:max-w-[460px] ml-auto rounded-2xl shadow-md overflow-hidden">
                <img src="footer.jpg" alt="about us" />
              </div>
            </div>
          </section>

          <section className="mx-auto max-w-[500px] mt-16 text-center">
            <p className="text-[2.5rem] font-semibold text-[#228ce0] relative px-8">
              <span className="text-[4rem] absolute -top-[2rem] -left-[0rem] pl-2">
                <pre>“</pre>
              </span>
              <span className="italic font-lato">
              Vi lindrar din stress och levererar de bästa erbjudandena direkt in
                din hand.
              </span>
              {/* <span className="italic font-lato">
                We relieve your stress and deliver the best deals right into
                your hand.
              </span> */}
            </p>
          </section>

          <section className="max-w-[600px] md:max-w-[460px] mx-auto md:max-w-full px-4 mt-16">
            <h2 className="text-[#228ce0] text-[1.5rem] font-semibold font-lato">
              {/* Vision & Mission */}
              Vision och Mission
            </h2>
            <section className="flex flex-col md:flex-row flex-wrap md:flex-nowrap mx-auto gap-x-8">
              <div className="bg-[#afcff0] rounded-xl px-8 py-8 shadow-xl w-full md:max-w-[360px] mt-8">
                <p className="font-lato text-[1.05rem]">
                Vi strävar efter att samarbeta med företag och
                  influencers att erbjuda en konstant ström av rabattkoder och
                  erbjudanden till våra kunder.
                </p>
                {/* <p className="font-lato text-[1.05rem]">
                  We strive to work collaboratively with companies and
                  influencers to offer a constant stream of discount codes and
                  offers to our customers.
                </p> */}
              </div>
              <div className="bg-[#afcff0] rounded-2xl px-8 py-8 shadow-xl w-full md:max-w-[360px] mt-8">
                <p className="font-lato text-[1.05rem]">
                Att bli den ledande destinationen för kunder som vill
                  spara pengar på sina inköp.
                </p>
                {/* <p className="font-lato text-[1.05rem]">
                  To become the leading destination for customers looking to
                  save money on their purchases.
                </p> */}
              </div>
              <div className="bg-[#afcff0] rounded-2xl px-8 py-8 shadow-xl w-full md:max-w-[360px] mt-8">
                <p className="font-lato text-[1.05rem]">
                För att ge kunderna tillgång till ett brett utbud av rabatter
                  koder och erbjudanden, vilket gör att de kan spara pengar på sina
                  köp.
                </p>
                {/* <p className="font-lato text-[1.05rem]">
                  To provide customers with access to a wide range of discount
                  codes and offers, enabling them to save money on their
                  purchases.
                </p> */}
              </div>
            </section>
          </section>
        </div>
      </div>
    </main>
  );
};

export default AboutUs;
