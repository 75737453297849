/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { updateCoupon, uploadImage } from "../helper";
import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import DataTable from "react-data-table-component";

import {
  query,
  collection,
  limit,
  orderBy,
  serverTimestamp,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";

import { db } from "../firebase/firebase";
import { toast } from "react-toastify";

function Dashboard() {
  const [image, setImage] = useState();
  const loadingRef = useRef(null);
  const [ showOption, setShowOptions ] = useState(false)
  const [ couponData, setCouponData ] = useState({imageUrl: "", couponCode: ""})





  const ref = collection(db, "coupons");
  const mutation = useFirestoreCollectionMutation(ref);

  // Define a query reference using the Firebase SDK
  const Qeryref = query(collection(db, "coupons"), orderBy("timestamp", "desc"))
  // Provide the query to the hook
  const couponsQuery = useFirestoreQuery(["coupons"], Qeryref, {
    subscribe: true,
  });

  if (couponsQuery.isLoading) {
    return <div>Loading...</div>;
  }
  const couponDataSnapshot = couponsQuery.data;




  const submitHandler = async (e) => {
    e.preventDefault();

    loadingRef.current.classList.add("loading");
    const form = new FormData(e.target);
    const formData = Object.fromEntries(form.entries());
    const couponCode = formData.couponCode.trim();

    //Upload image and retrieve Image URL
    const imageUrl = await uploadImage(image);

    try {
      //Initiate Mutation to firestore
      mutation.mutate({
        couponCode,
        imageUrl,
        timestamp: serverTimestamp(),
      });
      toast.success("Coupon Added");
      loadingRef.current.classList.remove("loading");
      e.target.reset();
    } catch (error) {
      console.log(error);
      toast.error("Try again");
      loadingRef.current.classList.remove("loading");
    }
  };


  return (
    <div className="drawer">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="w-full navbar bg-base-300">
          <div className="flex-none lg:hidden">
            <label htmlFor="my-drawer-3" className="btn btn-square btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>
          <div className="flex-1 px-2 mx-2">SALTVATTEN</div>
          <div className="flex-none hidden lg:block">
            <ul className="menu menu-horizontal">
              {/* The button to open modal */}
              <li>
                <a>
                  <label htmlFor="my-modal-5">Add Coupon</label>
                </a>
              </li>
              <li>
                <a>Log out</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="overflow-x-auto w-full mt-20 flex justify-center">
          <table className="table w-[800px]">
            <thead>
              <tr>
                <th>Image</th>
                <th>Coupon Code</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>

            {couponDataSnapshot.docs.map((docSnapshot) => {

              const coupon = docSnapshot.data()

              return <tr key={docSnapshot.id} onMouseOver={() => {

                setShowOptions(true)
               }}             
               onMouseLeave = { () => {
                setShowOptions(false)
               }}>
              <td>
                <div className="flex items-center space-x-3">
                  <div className="avatar">
                    <div className="mask mask-squircle w-12 h-12">
                      <img
        
                        src={coupon.imageUrl}
                        alt="Avatar Tailwind CSS Component"
                      />

                    
                    </div>
                    
                  </div>

                  { 
                  
                   showOption ?  <div className="flex gap-2">
                       <a>
                  <label onClick={() => {

                    const code = coupon.couponCode
                    const imageUrl = coupon.imageUrl 

                    const data = {
                      code,
                      imageUrl
                    }
                    setCouponData(data)
                    console.log(data)
                  }} htmlFor="my-modal-5">Edit</label>
                </a>|

                        <a href="" >Delete</a>


                        |
                        <a href="" >View</a>

                      </div> : "" 
                      
                      }
                 
                </div>
              </td>
              <td>{coupon.couponCode}</td>
              <td></td>
              <th>
                <button className="btn btn-ghost btn-xs"></button>
              </th>
            </tr>
            })}

        
            </tbody>

            <tfoot>
              <tr>
                <th>Image</th>
                <th>Coupon Code</th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <ul className="menu p-4 w-80 bg-base-100">
          <li>
            <a>Sidebar Item 1</a>
          </li>
          <li>
            <a>Sidebar Item 2</a>
          </li>
        </ul>
      </div>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h1 className="font-bold text-lg text-center text-3xl">Add Coupon</h1>

          <form onSubmit={submitHandler} className="mt-10">
            <div className="form-control w-full max-w-xs my-5">
              <label className="label">
                <span className="label-text text-lg font-bold">
                  {" "}
                  Coupon Code
                </span>
              </label>

              <input
               
                name="couponCode"
                required
                type="text"
                placeholder="Enter code"
                className="input input-bordered w-full max-w-xs"
              />
            </div>

            <div>
              <label className="label">
                <span className="label-text text-lg font-bold">
                  {" "}
                  Coupon Image
                </span>
              </label>
              <input
                accept=".jpg,.png,.jpeg"
                onChange={(e) => {
                  setImage(e.target.files);
                }}
                
                type="file"
                className="file-input file-input-bordered file-input-md w-full max-w-xs"
              />
            </div>

            <div className="my-12">
              <button
                ref={loadingRef}
                type="submit"
                className="w-40 btn btn-md normal-case rounded-full"
              >
                Add coupon
              </button>
            </div>
          </form>

          <div className="modal-action">
            <label
              htmlFor="my-modal-5"
              className="btn normal-case rounded-full w-[100px]"
            >
              Done
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
