import { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import axios from "axios";

import AboutUs from "./pages/AboutUs";
import Index from "./External Folder/Index";
import Acasting from "./External Folder/Acasting";

import CouponCard from "./components/CouponCard";
import ContactUs from "./pages/ContactUs";
import { Demo } from "./pages/Demo";

function App() {
  const [couponData, setCouponData] = useState([]);

  useEffect(() => {
    console.log("Initiate call to wordpress");
    axios
      .get("https://admin.saltvatten.se/wp-json/wp/v2/coupon")
      .then((coupon) => {
        setCouponData(coupon.data);
      });
  }, []);

  const DataFromCoupons = couponData?.map((coupon, index) => (
    <CouponCard key={index} coupon={coupon} />
  ));

  const [search, searchF] = useState("");

  const [searchActive, searchActiveF] = useState(false);

  function handleSearch(params) {
    searchActiveF(!searchActive);
  }

  const SearchedDataFromCoupons = couponData?.map((coupon, index) => {
    if (
      coupon.title.rendered.toLocaleUpperCase() ===
        search.trim().toLocaleUpperCase() ||
      coupon.acf.company_name?.toLocaleUpperCase() ===
        search.trim().toLocaleUpperCase() ||
      coupon.acf.influentcer_name?.toLocaleUpperCase() ===
        search.trim().toLocaleUpperCase()
    ) {
      return <CouponCard key={index} coupon={coupon} />;
    }
  });

  const [pageType, pageTypeF] = useState("Homepage");

  return (
    <div className="bg-[url('./img/bg.png')] w-full min-h-screen bg-center bg-cover bg-fixed bg-no-repeat">
      <ToastContainer position="top-center" />

      <div className="flex flex-col items-center ">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  search={search}
                  couponData={couponData}
                  searchActive={searchActive}
                  SearchedDataFromCoupons={SearchedDataFromCoupons}
                  DataFromCoupons={DataFromCoupons}
                  searchF={searchF}
                  handleSearch={handleSearch}
                  pageType={pageType}
                  pageTypeF={pageTypeF}
                />
              }
            />
            <Route path="/administrator" element={<Dashboard />} />
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/about" element={<AboutUs />} />
            {/* <Route path="/kontakta-oss" element={<ContactUs />} /> */}
            <Route path="/demo" element={<Demo />} />
            <Route
              path="/kontakta-oss"
              element={<ContactUs pageType={pageType} pageTypeF={pageTypeF} />}
            />
            <Route path="/externalfolder" element={<Index />} />
            <Route path="/acasting" element={<Acasting />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
