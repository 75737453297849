import React from "react";

function Acasting() {
  return (
    <div className="flex flex-col justify-center items-center bg-white h-full w-full pb-11    px-5">
      <div className="flex flex-col mb-9">
        <div className="my-10">
          <p className="text-center text-black font-bold text-[20px]">Djur</p>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-7 items-center justify-center ">
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="a.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px] rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="b.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="c.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="d.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="e.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
        </div>

        <button className="uppercase bg-[#11aebf] p-3 text-white text-[15px] text-center cursor-pointer font-semibold hover:bg-[#289daa] rounded-md">
          Se alla djur
        </button>
      </div>
      <div className="flex flex-col mb-9">
        <div className="my-10">
          <p className="text-center text-black font-bold text-[20px]">
            Locations
          </p>
        </div>

        <div className="grid sm:grid-cols-2 grid-cols-1 gap-7 items-center justify-center ">
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="a.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px] rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="b.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="c.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="d.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full  sm:w-[500px] rounded-2xl shadow-lg   mb-[60px] sm:mb-[30px]">
            <div className="cursor-pointer ">
              <img
                src="e.jpg"
                alt="blogImg"
                className="w-[100%] h-[300px]  rounded-t-2xl"
              />
            </div>

            <div className="border rounded-b-2xl">
              <div className=" sm:p-[30px] p-[10px] py-[20px]">
                <div className="text-black text-[12px] mb-[5px]  uppercase ">
                  <span>Posted on 9 FEB</span>
                </div>

                <a
                  href="blog-standard-post.html"
                  className="text-black uppercase text-[16px]  font-bold mb-[10px] "
                >
                  Djur - Hund - Underbettet Esty
                </a>

                <p
                  className="mt-2 text-[12px]  text-black
                 "
                >
                  Esty är en blandras med ett världsklassigt underbett! Hon är
                  känd för sin ful söta charm med sina gula tänder i sitt
                  underbett som alltid syns först.
                </p>

                <button className="bg-black border-black hover:bg-white hover:text-black text-white leading-5 text-[9px] px-[17px] py-1 uppercase rounded-[5px] mt-4 font-bold transition-colors delay-200 duration-300">
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acasting;
