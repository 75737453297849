import { useEffect, useState } from "react";

const useBodyScrollLoc = () => {
  const bodyStyle = document.body.style;

  const [isLocked, isLockedF] = useState(false);

  useEffect(() => {
    bodyStyle.overflowY = isLocked ? "hidden" : "auto";
  }, [isLocked, bodyStyle]);

  const toggle = () => {
    isLockedF(true);
  };
  const togglel = () => {
    isLockedF(false);
  };
  const togglell = () => {
    isLockedF(!isLocked);
  };

  return [togglell, toggle, togglel, isLocked];
};

export default useBodyScrollLoc;
