import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";

import contact from "../components/contact-us.png";
import formFill from "../components/form_fill.png";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const initialState = {
  Namn: "",
  Epost: "",
  Amne: "",
  Meddelande: "",
};

const ContactUs = ({ pageTypeF}) => {
  useEffect(() => {
    pageTypeF("Contact");
  }, []);


  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [form, setform] = useState(initialState);

  const { Namn, Epost, Amne, Meddelande } = form;

  const sendEmail = () => {
    emailjs
      .send(
        "service_yukqhrt",
        "template_akoei5t",
        {
          coupon: `Namn: ${Namn}, Epost: ${Epost}, Amne: ${Amne}, Meddelande: ${Meddelande} `,
        },
        "FRs_bxr3mWbkiPpgi"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setform(initialState);
          toast("Email Sent");
        },
        function (error) {
          console.log("FAILED...", error);
          toast("Email Failed!");
        }
      );
  };

  return (
    <>
      <Navbar />

      <div className="bg-white min-h-screen relative lg:py-5 w-full">
        <div
          className="flex flex-col items-center justify-between pr-5 pl-5  mr-auto ml-auto max-w-7xl
        xl:px-5 lg:flex-row"
        >
          <div className="flex flex-col items-center w-full pt-5 pb-10  lg:pt-10 lg:flex-row">
            <div className="w-full  relative max-w-md lg:max-w-2xl lg:w-7/12">
              <div className="flex flex-col justify-center w-full h-full relative lg:pr-10">
                <p className="lg:text-5xl text-start text-2xl text-slate-700 font-medium py-5">
                  Kontakta oss
                </p>
                <p className="lg:text-xl text-slate-700 font-normal">
                  Här kan du skicka in en förfrågan om att synas på vår hemsida
                  som företag eller privatperson. Vi är alltid på jakt efter nya
                  samarbeten och att visa upp inspirerande och relevant innehåll
                  för våra besökare.
                </p>
              </div>
            </div>
            <div className="w-full  relative z-10 max-w-2xl lg:mt-0 lg:w-10/12">
              <div className="flex flex-col items-start justify-start pt-10 pr-5 pb-5 pl-5 ">
                <div className="w-full relative">
                  <img
                    src={contact}
                    width={900}
                    height={850}
                    className=" w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col items-center justify-between pr-5 pl-5  mr-auto ml-auto max-w-7xl
        xl:px-5 lg:flex-row"
        >
          <div className="flex flex-col items-center w-full pt-5  pb-20 lg:pt-10 lg:flex-row  ">
            <div className="w-full  relative max-w-md lg:max-w-2xl lg:w-7/12">
              <div className="flex flex-col items-center justify-center w-full h-full relative lg:pr-10">
                <p className="lg:text-2xl text-slate-700 font-medium">
                  Fyll i formuläret nedan med så mycket information som möjligt
                  om dig och ditt företag/personliga projekt. Vi kommer att ta
                  en titt på din förfrågan och återkomma till dig inom kort.
                </p>
                <img src={formFill} width={400} height={400} className=" p-5" />
              </div>
            </div>
            <div className="w-full mt-20 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
              <div
                className="flex flex-col items-start justify-start pt-10 pr-5 pb-10 pl-5 bg-white shadow-2xl rounded-xl
            relative z-10"
              >
                <div className="w-full mt-5  relative space-y-5">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Namn"
                      value={Namn}
                      required
                      onChange={(e) =>
                        setform({
                          ...form,
                          Namn: e.target.value,
                        })
                      }
                      className="input input-bordered input-lg w-full bg-white focus:outline-none"
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="email"
                      placeholder="E-post"
                      value={Epost}
                      required
                      onChange={(e) =>
                        setform({
                          ...form,
                          Epost: e.target.value,
                        })
                      }
                      className="input input-bordered input-lg w-full bg-white focus:outline-none"
                    />
                  </div>
                  <div classNa="relative">
                    <input
                      type="text"
                      placeholder="Ämne"
                      value={Amne}
                      required
                      onChange={(e) =>
                        setform({
                          ...form,
                          Amne: e.target.value,
                        })
                      }
                      className="input input-bordered input-lg w-full bg-white focus:outline-none"
                    />
                  </div>
                  <div className="relative">
                    <textarea
                      placeholder="Meddelande"
                      value={Meddelande}
                      rows="4"
                      required
                      onChange={(e) =>
                        setform({
                          ...form,
                          Meddelande: e.target.value,
                        })
                      }
                      className="textarea textarea-bordered text-lg textarea-lg w-full bg-white focus:outline-none "
                    ></textarea>
                  </div>
                  <div className="relative">
                    <button
                      onClick={() => sendEmail()}
                      className="w-full inline-block pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white bg-indigo-500
                  rounded-lg transition duration-200 hover:bg-indigo-600 ease"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {windowSize[0] > 500 && (
                <>
                  <svg
                    viewbox="0 0 91 91"
                    className="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
            fill-current"
                  >
                    <g stroke="none" strokewidth="1" fillrule="evenodd">
                      <g fillrule="nonzero">
                        <g>
                          <g>
                            <circle cx="3.261" cy="3.445" r="2.72" />
                            <circle cx="15.296" cy="3.445" r="2.719" />
                            <circle cx="27.333" cy="3.445" r="2.72" />
                            <circle cx="39.369" cy="3.445" r="2.72" />
                            <circle cx="51.405" cy="3.445" r="2.72" />
                            <circle cx="63.441" cy="3.445" r="2.72" />
                            <circle cx="75.479" cy="3.445" r="2.72" />
                            <circle cx="87.514" cy="3.445" r="2.719" />
                          </g>
                          <g transform="translate(0 12)">
                            <circle cx="3.261" cy="3.525" r="2.72" />
                            <circle cx="15.296" cy="3.525" r="2.719" />
                            <circle cx="27.333" cy="3.525" r="2.72" />
                            <circle cx="39.369" cy="3.525" r="2.72" />
                            <circle cx="51.405" cy="3.525" r="2.72" />
                            <circle cx="63.441" cy="3.525" r="2.72" />
                            <circle cx="75.479" cy="3.525" r="2.72" />
                            <circle cx="87.514" cy="3.525" r="2.719" />
                          </g>
                          <g transform="translate(0 24)">
                            <circle cx="3.261" cy="3.605" r="2.72" />
                            <circle cx="15.296" cy="3.605" r="2.719" />
                            <circle cx="27.333" cy="3.605" r="2.72" />
                            <circle cx="39.369" cy="3.605" r="2.72" />
                            <circle cx="51.405" cy="3.605" r="2.72" />
                            <circle cx="63.441" cy="3.605" r="2.72" />
                            <circle cx="75.479" cy="3.605" r="2.72" />
                            <circle cx="87.514" cy="3.605" r="2.719" />
                          </g>
                          <g transform="translate(0 36)">
                            <circle cx="3.261" cy="3.686" r="2.72" />
                            <circle cx="15.296" cy="3.686" r="2.719" />
                            <circle cx="27.333" cy="3.686" r="2.72" />
                            <circle cx="39.369" cy="3.686" r="2.72" />
                            <circle cx="51.405" cy="3.686" r="2.72" />
                            <circle cx="63.441" cy="3.686" r="2.72" />
                            <circle cx="75.479" cy="3.686" r="2.72" />
                            <circle cx="87.514" cy="3.686" r="2.719" />
                          </g>
                          <g transform="translate(0 49)">
                            <circle cx="3.261" cy="2.767" r="2.72" />
                            <circle cx="15.296" cy="2.767" r="2.719" />
                            <circle cx="27.333" cy="2.767" r="2.72" />
                            <circle cx="39.369" cy="2.767" r="2.72" />
                            <circle cx="51.405" cy="2.767" r="2.72" />
                            <circle cx="63.441" cy="2.767" r="2.72" />
                            <circle cx="75.479" cy="2.767" r="2.72" />
                            <circle cx="87.514" cy="2.767" r="2.719" />
                          </g>
                          <g transform="translate(0 61)">
                            <circle cx="3.261" cy="2.846" r="2.72" />
                            <circle cx="15.296" cy="2.846" r="2.719" />
                            <circle cx="27.333" cy="2.846" r="2.72" />
                            <circle cx="39.369" cy="2.846" r="2.72" />
                            <circle cx="51.405" cy="2.846" r="2.72" />
                            <circle cx="63.441" cy="2.846" r="2.72" />
                            <circle cx="75.479" cy="2.846" r="2.72" />
                            <circle cx="87.514" cy="2.846" r="2.719" />
                          </g>
                          <g transform="translate(0 73)">
                            <circle cx="3.261" cy="2.926" r="2.72" />
                            <circle cx="15.296" cy="2.926" r="2.719" />
                            <circle cx="27.333" cy="2.926" r="2.72" />
                            <circle cx="39.369" cy="2.926" r="2.72" />
                            <circle cx="51.405" cy="2.926" r="2.72" />
                            <circle cx="63.441" cy="2.926" r="2.72" />
                            <circle cx="75.479" cy="2.926" r="2.72" />
                            <circle cx="87.514" cy="2.926" r="2.719" />
                          </g>
                          <g transform="translate(0 85)">
                            <circle cx="3.261" cy="3.006" r="2.72" />
                            <circle cx="15.296" cy="3.006" r="2.719" />
                            <circle cx="27.333" cy="3.006" r="2.72" />
                            <circle cx="39.369" cy="3.006" r="2.72" />
                            <circle cx="51.405" cy="3.006" r="2.72" />
                            <circle cx="63.441" cy="3.006" r="2.72" />
                            <circle cx="75.479" cy="3.006" r="2.72" />
                            <circle cx="87.514" cy="3.006" r="2.719" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <svg
                    viewbox="0 0 91 91"
                    className="absolute -bottom-9 -right-9 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
            fill-current"
                  >
                    <g stroke="none" strokewidth="1" fillrule="evenodd">
                      <g fillrule="nonzero">
                        <g>
                          <g>
                            <circle cx="3.261" cy="3.445" r="2.72" />
                            <circle cx="15.296" cy="3.445" r="2.719" />
                            <circle cx="27.333" cy="3.445" r="2.72" />
                            <circle cx="39.369" cy="3.445" r="2.72" />
                            <circle cx="51.405" cy="3.445" r="2.72" />
                            <circle cx="63.441" cy="3.445" r="2.72" />
                            <circle cx="75.479" cy="3.445" r="2.72" />
                            <circle cx="87.514" cy="3.445" r="2.719" />
                          </g>
                          <g transform="translate(0 12)">
                            <circle cx="3.261" cy="3.525" r="2.72" />
                            <circle cx="15.296" cy="3.525" r="2.719" />
                            <circle cx="27.333" cy="3.525" r="2.72" />
                            <circle cx="39.369" cy="3.525" r="2.72" />
                            <circle cx="51.405" cy="3.525" r="2.72" />
                            <circle cx="63.441" cy="3.525" r="2.72" />
                            <circle cx="75.479" cy="3.525" r="2.72" />
                            <circle cx="87.514" cy="3.525" r="2.719" />
                          </g>
                          <g transform="translate(0 24)">
                            <circle cx="3.261" cy="3.605" r="2.72" />
                            <circle cx="15.296" cy="3.605" r="2.719" />
                            <circle cx="27.333" cy="3.605" r="2.72" />
                            <circle cx="39.369" cy="3.605" r="2.72" />
                            <circle cx="51.405" cy="3.605" r="2.72" />
                            <circle cx="63.441" cy="3.605" r="2.72" />
                            <circle cx="75.479" cy="3.605" r="2.72" />
                            <circle cx="87.514" cy="3.605" r="2.719" />
                          </g>
                          <g transform="translate(0 36)">
                            <circle cx="3.261" cy="3.686" r="2.72" />
                            <circle cx="15.296" cy="3.686" r="2.719" />
                            <circle cx="27.333" cy="3.686" r="2.72" />
                            <circle cx="39.369" cy="3.686" r="2.72" />
                            <circle cx="51.405" cy="3.686" r="2.72" />
                            <circle cx="63.441" cy="3.686" r="2.72" />
                            <circle cx="75.479" cy="3.686" r="2.72" />
                            <circle cx="87.514" cy="3.686" r="2.719" />
                          </g>
                          <g transform="translate(0 49)">
                            <circle cx="3.261" cy="2.767" r="2.72" />
                            <circle cx="15.296" cy="2.767" r="2.719" />
                            <circle cx="27.333" cy="2.767" r="2.72" />
                            <circle cx="39.369" cy="2.767" r="2.72" />
                            <circle cx="51.405" cy="2.767" r="2.72" />
                            <circle cx="63.441" cy="2.767" r="2.72" />
                            <circle cx="75.479" cy="2.767" r="2.72" />
                            <circle cx="87.514" cy="2.767" r="2.719" />
                          </g>
                          <g transform="translate(0 61)">
                            <circle cx="3.261" cy="2.846" r="2.72" />
                            <circle cx="15.296" cy="2.846" r="2.719" />
                            <circle cx="27.333" cy="2.846" r="2.72" />
                            <circle cx="39.369" cy="2.846" r="2.72" />
                            <circle cx="51.405" cy="2.846" r="2.72" />
                            <circle cx="63.441" cy="2.846" r="2.72" />
                            <circle cx="75.479" cy="2.846" r="2.72" />
                            <circle cx="87.514" cy="2.846" r="2.719" />
                          </g>
                          <g transform="translate(0 73)">
                            <circle cx="3.261" cy="2.926" r="2.72" />
                            <circle cx="15.296" cy="2.926" r="2.719" />
                            <circle cx="27.333" cy="2.926" r="2.72" />
                            <circle cx="39.369" cy="2.926" r="2.72" />
                            <circle cx="51.405" cy="2.926" r="2.72" />
                            <circle cx="63.441" cy="2.926" r="2.72" />
                            <circle cx="75.479" cy="2.926" r="2.72" />
                            <circle cx="87.514" cy="2.926" r="2.719" />
                          </g>
                          <g transform="translate(0 85)">
                            <circle cx="3.261" cy="3.006" r="2.72" />
                            <circle cx="15.296" cy="3.006" r="2.719" />
                            <circle cx="27.333" cy="3.006" r="2.72" />
                            <circle cx="39.369" cy="3.006" r="2.72" />
                            <circle cx="51.405" cy="3.006" r="2.72" />
                            <circle cx="63.441" cy="3.006" r="2.72" />
                            <circle cx="75.479" cy="3.006" r="2.72" />
                            <circle cx="87.514" cy="3.006" r="2.719" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
