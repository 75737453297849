import React from "react";

function Index() {
  return (
    <div className="flex flex-col bg-white h-full w-full pb-11 md:px-36 lg:px-44 sm:px-22 px-5">
      <div className="my-10">
        <p className="text-center text-black text-[18px]">My Profile</p>
      </div>

      <div className="flex flex-col sm:flex-row items-center my-10">
        <img src="img1.png" alt="" />
        <img src="img2.png" alt="" />
      </div>

      <div className="flex flex-col">
        <p className="mb-4 font-semibold text-black text-[17px]">
          David McQarphui, age 29, member 1548518
        </p>

        <p className="text-black font-semibold py-0.5">
          Gender: <span className=" text-[13.5px] font-normal">Male</span>
        </p>
        <p className="text-black font-semibold py-0.5">
          Email:{" "}
          <span className=" text-[13.5px] font-normal">
            {" "}
            phrankdhevid@gmail.com
          </span>
        </p>
        <p className="text-black font-semibold py-0.5">
          Mobile number:{" "}
          <span className=" text-[13.5px] font-normal">0247627952</span>
        </p>
        <p className="text-black font-semibold py-0.5">
          County: <span className=" text-[13.5px] font-normal">Stockholm</span>
        </p>
        <p className="text-black font-semibold py-0.5">
          Ethnicity:{" "}
          <span className=" text-[13.5px] font-normal">Scandinavia</span>
        </p>
        <p className="text-black font-semibold py-0.5">
          Hair color: <span className=" text-[13.5px] font-normal">Black</span>
        </p>
        <p className="text-black font-semibold py-0.5">
          County to work in:{" "}
          <span className=" text-[13.5px] font-normal">
            {" "}
            Kronoberg, Norrbotten, Agder
          </span>
        </p>
        <button className="text-[#11aebf] font-semibold flex my-4 y-0.5">
          Edit your details
        </button>

        <p className="text-black font-semibold text-[17px] mt-3 py-1">
          Languages and dialects
        </p>

        <p className="text-black font-semibold py-0.5">
          English: <span className=" text-[13.5px] font-normal"> Fluent</span>
        </p>
      </div>

      <div className="flex flex-col mt-5">
        <p className="text-black font-semibold text-[17px] mt-3 py-1">
          Experience
        </p>

        <div className="flex flex-col">
          <div className="flex items-center py-1 justify-between border-b-2 border-[#cccccc]">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Work as
            </p>
            <p className="text-black font-semibold py-0.5 flex-1">Yes No</p>
            <p className="text-black font-semibold py-0.5 flex-1">Ex</p>
            <p className="text-black font-semibold py-0.5 flex-1">Ext</p>
            <p className="text-black font-semibold py-0.5 flex-1">Job</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Extra
            </p>
            <p className="text-black  py-0.5 flex-1">Yes</p>
            <p className="text-black  py-0.5 flex-1">-</p>
            <p className="text-black  py-0.5 flex-1">-</p>
            <p className="text-black  py-0.5 flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Actor
            </p>
            <p className="text-black py-0.5 flex-1">Yes</p>
            <p className="text-black py-0.5 flex-1">A little</p>
            <p className="text-black py-0.5 flex-1">Short.</p>
            <p className="text-black py-0.5 flex-1">1-5</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Model
            </p>
            <p className="text-black py-0.5 font-normal flex-1">Yes</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              TV participant
            </p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Voice Job
            </p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Audience
            </p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Clothing stylist
            </p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Hair stylist
            </p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
          <div className="flex items-center py-1 justify-between ">
            <p style={{ flex: 2 }} className="text-black font-semibold py-0.5 ">
              Makeup artist
            </p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
            <p className="text-black py-0.5 font-normal flex-1">-.</p>
            <p className="text-black py-0.5 font-normal flex-1">-</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
