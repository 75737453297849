import React, { useEffect } from "react";
import Navbar from "../components/Navbar";

export default function Home({
  searchActive,
  SearchedDataFromCoupons,
  DataFromCoupons,
  search,
  searchF,
  handleSearch,
  pageTypeF,
  pageType,
}) {
  useEffect(() => {
    pageTypeF("Homepage");
  }, []);

  return (
    <>
      <Navbar
        pageType={pageType}
        pageTypeF={pageTypeF}
        search={search}
        searchF={searchF}
        handleSearch={handleSearch}
      />

      <div className="flex flex-col items-center relative my-10 ">
        {searchActive && search ? SearchedDataFromCoupons : DataFromCoupons}
      </div>
    </>
  );
}
