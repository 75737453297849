import { storage } from "./firebase/firebase";
import {
  //getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

export const uploadImage = async (image) => {
  //Store image in firebase
  //const storage = getStorage();
  const imageName = image[0].name;
  // get extension
  const extension = imageName.substring(imageName.lastIndexOf(".") + 1);
  const fileName = `coupon-image.${extension}`;

  const storageRef = ref(storage, "images/" + fileName);
  await uploadBytes(storageRef, image[0]);

  const downloadUrl = await getDownloadURL(storageRef);
  console.log(downloadUrl);

  return downloadUrl;
};





export const updateCoupon = (docId) => {

  console.log(docId)

return null

}