
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgfZsKzL17PAFTT3U2NLjXo32lHUAyqM4",
  authDomain: "saltvatten-e140d.firebaseapp.com",
  projectId: "saltvatten-e140d",
  storageBucket: "saltvatten-e140d.appspot.com",
  messagingSenderId: "931214500484",
  appId: "1:931214500484:web:a59f121c8b0057fabf98d1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);


